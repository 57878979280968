import { default as affiliatesVrprGMoFXiMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/affiliates.vue?macro=true";
import { default as database_45viewsiMdpnth0txMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/database-views.vue?macro=true";
import { default as discount_45students_45academics_45ngos4ItBC2n5noMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/discount-students-academics-ngos.vue?macro=true";
import { default as _91slug_93HLcugBegEXMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/features/[slug].vue?macro=true";
import { default as index83GgbM1XUoMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/features/index.vue?macro=true";
import { default as _91slug_93nQq1CD7qXbMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/form-templates/[slug].vue?macro=true";
import { default as index2ruU1f5sfZMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/form-templates/index.vue?macro=true";
import { default as _91slug_93ZnezFnRUvsMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/form-templates/industries/[slug].vue?macro=true";
import { default as _91slug_93L23oUTUR4tMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/form-templates/types/[slug].vue?macro=true";
import { default as editBt5OtonJpuMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/forms/[slug]/edit.vue?macro=true";
import { default as indexhhl0Nsq8uuMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/forms/[slug]/index.vue?macro=true";
import { default as analytics6yT8moYJ1uMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/forms/[slug]/show/analytics.vue?macro=true";
import { default as indexYK8GNVh5QAMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/forms/[slug]/show/index.vue?macro=true";
import { default as indexgNq1yn3lmnMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue?macro=true";
import { default as shareD2miqe5RUbMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/forms/[slug]/show/share.vue?macro=true";
import { default as showThfh5QVQ1sMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/forms/[slug]/show.vue?macro=true";
import { default as indexFLzz6FS6qjMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/forms/create/index.vue?macro=true";
import { default as uploadsj9W8jhiDcaMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/forms/uploads.vue?macro=true";
import { default as guideskf7oG67mRpMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/guides.vue?macro=true";
import { default as homeejBwB0zLDrMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/home.vue?macro=true";
import { default as indexeq1fXCRHLdMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/index.vue?macro=true";
import { default as integrationsH6xw4Lk9OOMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/integrations.vue?macro=true";
import { default as loginQ4iGsW0SLdMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/login.vue?macro=true";
import { default as my_45templatesNIyRdBnOFvMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/my-templates.vue?macro=true";
import { default as notion_45ambassadors_45influencers3wOdOMGuh3Meta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/notion-ambassadors-influencers.vue?macro=true";
import { default as _91slug_93t9L0GF8bGcMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/notion-form/[slug].vue?macro=true";
import { default as callbackQZ8R9sJbxtMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/notion/callback.vue?macro=true";
import { default as guest_45callback3fsYYxck0mMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/notion/guest-callback.vue?macro=true";
import { default as callbackZCrWFVkjEdMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/oauth/callback.vue?macro=true";
import { default as partnersXeeGPGtXbmMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/partners.vue?macro=true";
import { default as _91token_93Cs6mQg9XEzMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/password/reset/[token].vue?macro=true";
import { default as indexoSYHoZqnvKMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/password/reset/index.vue?macro=true";
import { default as pricingfZXvVYO8RGMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/pricing.vue?macro=true";
import { default as privacy_45policykDsriv2LXLMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/privacy-policy.vue?macro=true";
import { default as registerMzHW7Ea7d6Meta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/register.vue?macro=true";
import { default as access_45tokensS8DOgTWbPWMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/settings/access-tokens.vue?macro=true";
import { default as accountNH02FgtAVaMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/settings/account.vue?macro=true";
import { default as adminDaey0iCB4fMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/settings/admin.vue?macro=true";
import { default as billingkF5vtpXbwXMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/settings/billing.vue?macro=true";
import { default as connectionsX0LkEngkfhMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/settings/connections.vue?macro=true";
import { default as custom_45domainm6xz3YuNPiMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/settings/custom-domain.vue?macro=true";
import { default as indexNTmrUSRWYOMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/settings/index.vue?macro=true";
import { default as passwordzZk9kZQRSdMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/settings/password.vue?macro=true";
import { default as profileym752boy9PMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/settings/profile.vue?macro=true";
import { default as workspacese22c0BwJ0CMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/settings/workspaces.vue?macro=true";
import { default as settingst6ZJUu99MhMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/settings.vue?macro=true";
import { default as errorzbs0UebeVBMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/subscriptions/error.vue?macro=true";
import { default as success6j15G8QwjtMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/subscriptions/success.vue?macro=true";
import { default as terms_45conditionsOL1HeskQUyMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/terms-conditions.vue?macro=true";
import { default as edityln2Y6YGecMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/views/[slug]/edit.vue?macro=true";
import { default as index2Myx3WLy2NMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/views/[slug]/index.vue?macro=true";
import { default as indexUxXc7eKnS2Meta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/views/[slug]/show/index.vue?macro=true";
import { default as sharefPThBvr6LIMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/views/[slug]/show/share.vue?macro=true";
import { default as showYg4qbfivDOMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/views/[slug]/show.vue?macro=true";
import { default as indexfwC6rgHGkoMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/views/create/index.vue?macro=true";
import { default as indexb7hw1huBstMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/views/index.vue?macro=true";
import { default as uploadsEkxyEqT69XMeta } from "/codebuild/output/src2320907562/src/notionforms/client/pages/views/uploads.vue?macro=true";
export default [
  {
    name: affiliatesVrprGMoFXiMeta?.name ?? "affiliates",
    path: affiliatesVrprGMoFXiMeta?.path ?? "/affiliates",
    meta: affiliatesVrprGMoFXiMeta || {},
    alias: affiliatesVrprGMoFXiMeta?.alias || [],
    redirect: affiliatesVrprGMoFXiMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/affiliates.vue").then(m => m.default || m)
  },
  {
    name: database_45viewsiMdpnth0txMeta?.name ?? "database-views",
    path: database_45viewsiMdpnth0txMeta?.path ?? "/database-views",
    meta: database_45viewsiMdpnth0txMeta || {},
    alias: database_45viewsiMdpnth0txMeta?.alias || [],
    redirect: database_45viewsiMdpnth0txMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/database-views.vue").then(m => m.default || m)
  },
  {
    name: discount_45students_45academics_45ngos4ItBC2n5noMeta?.name ?? "discount-students-academics-ngos",
    path: discount_45students_45academics_45ngos4ItBC2n5noMeta?.path ?? "/discount-students-academics-ngos",
    meta: discount_45students_45academics_45ngos4ItBC2n5noMeta || {},
    alias: discount_45students_45academics_45ngos4ItBC2n5noMeta?.alias || [],
    redirect: discount_45students_45academics_45ngos4ItBC2n5noMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/discount-students-academics-ngos.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93HLcugBegEXMeta?.name ?? "features-slug",
    path: _91slug_93HLcugBegEXMeta?.path ?? "/features/:slug()",
    meta: _91slug_93HLcugBegEXMeta || {},
    alias: _91slug_93HLcugBegEXMeta?.alias || [],
    redirect: _91slug_93HLcugBegEXMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/features/[slug].vue").then(m => m.default || m)
  },
  {
    name: index83GgbM1XUoMeta?.name ?? "features",
    path: index83GgbM1XUoMeta?.path ?? "/features",
    meta: index83GgbM1XUoMeta || {},
    alias: index83GgbM1XUoMeta?.alias || [],
    redirect: index83GgbM1XUoMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/features/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93nQq1CD7qXbMeta?.name ?? "form-templates-slug",
    path: _91slug_93nQq1CD7qXbMeta?.path ?? "/form-templates/:slug()",
    meta: _91slug_93nQq1CD7qXbMeta || {},
    alias: _91slug_93nQq1CD7qXbMeta?.alias || [],
    redirect: _91slug_93nQq1CD7qXbMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/form-templates/[slug].vue").then(m => m.default || m)
  },
  {
    name: index2ruU1f5sfZMeta?.name ?? "form-templates",
    path: index2ruU1f5sfZMeta?.path ?? "/form-templates",
    meta: index2ruU1f5sfZMeta || {},
    alias: index2ruU1f5sfZMeta?.alias || [],
    redirect: index2ruU1f5sfZMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/form-templates/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93ZnezFnRUvsMeta?.name ?? "form-templates-industries-slug",
    path: _91slug_93ZnezFnRUvsMeta?.path ?? "/form-templates/industries/:slug()",
    meta: _91slug_93ZnezFnRUvsMeta || {},
    alias: _91slug_93ZnezFnRUvsMeta?.alias || [],
    redirect: _91slug_93ZnezFnRUvsMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/form-templates/industries/[slug].vue").then(m => m.default || m)
  },
  {
    name: _91slug_93L23oUTUR4tMeta?.name ?? "form-templates-types-slug",
    path: _91slug_93L23oUTUR4tMeta?.path ?? "/form-templates/types/:slug()",
    meta: _91slug_93L23oUTUR4tMeta || {},
    alias: _91slug_93L23oUTUR4tMeta?.alias || [],
    redirect: _91slug_93L23oUTUR4tMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/form-templates/types/[slug].vue").then(m => m.default || m)
  },
  {
    name: editBt5OtonJpuMeta?.name ?? "forms-slug-edit",
    path: editBt5OtonJpuMeta?.path ?? "/forms/:slug()/edit",
    meta: editBt5OtonJpuMeta || {},
    alias: editBt5OtonJpuMeta?.alias || [],
    redirect: editBt5OtonJpuMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/forms/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexhhl0Nsq8uuMeta?.name ?? "forms-slug",
    path: indexhhl0Nsq8uuMeta?.path ?? "/forms/:slug()",
    meta: indexhhl0Nsq8uuMeta || {},
    alias: indexhhl0Nsq8uuMeta?.alias || [],
    redirect: indexhhl0Nsq8uuMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/forms/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showThfh5QVQ1sMeta?.name ?? undefined,
    path: showThfh5QVQ1sMeta?.path ?? "/forms/:slug()/show",
    meta: showThfh5QVQ1sMeta || {},
    alias: showThfh5QVQ1sMeta?.alias || [],
    redirect: showThfh5QVQ1sMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/forms/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: analytics6yT8moYJ1uMeta?.name ?? "forms-slug-show-analytics",
    path: analytics6yT8moYJ1uMeta?.path ?? "analytics",
    meta: analytics6yT8moYJ1uMeta || {},
    alias: analytics6yT8moYJ1uMeta?.alias || [],
    redirect: analytics6yT8moYJ1uMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/forms/[slug]/show/analytics.vue").then(m => m.default || m)
  },
  {
    name: indexYK8GNVh5QAMeta?.name ?? "forms-slug-show",
    path: indexYK8GNVh5QAMeta?.path ?? "",
    meta: indexYK8GNVh5QAMeta || {},
    alias: indexYK8GNVh5QAMeta?.alias || [],
    redirect: indexYK8GNVh5QAMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/forms/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: indexgNq1yn3lmnMeta?.name ?? "forms-slug-show-integrations",
    path: indexgNq1yn3lmnMeta?.path ?? "integrations",
    meta: indexgNq1yn3lmnMeta || {},
    alias: indexgNq1yn3lmnMeta?.alias || [],
    redirect: indexgNq1yn3lmnMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/forms/[slug]/show/integrations/index.vue").then(m => m.default || m)
  },
  {
    name: shareD2miqe5RUbMeta?.name ?? "forms-slug-show-share",
    path: shareD2miqe5RUbMeta?.path ?? "share",
    meta: shareD2miqe5RUbMeta || {},
    alias: shareD2miqe5RUbMeta?.alias || [],
    redirect: shareD2miqe5RUbMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/forms/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexFLzz6FS6qjMeta?.name ?? "forms-create",
    path: indexFLzz6FS6qjMeta?.path ?? "/forms/create",
    meta: indexFLzz6FS6qjMeta || {},
    alias: indexFLzz6FS6qjMeta?.alias || [],
    redirect: indexFLzz6FS6qjMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/forms/create/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsj9W8jhiDcaMeta?.name ?? "forms-uploads",
    path: uploadsj9W8jhiDcaMeta?.path ?? "/forms/uploads",
    meta: uploadsj9W8jhiDcaMeta || {},
    alias: uploadsj9W8jhiDcaMeta?.alias || [],
    redirect: uploadsj9W8jhiDcaMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/forms/uploads.vue").then(m => m.default || m)
  },
  {
    name: guideskf7oG67mRpMeta?.name ?? "guides",
    path: guideskf7oG67mRpMeta?.path ?? "/guides",
    meta: guideskf7oG67mRpMeta || {},
    alias: guideskf7oG67mRpMeta?.alias || [],
    redirect: guideskf7oG67mRpMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/guides.vue").then(m => m.default || m)
  },
  {
    name: homeejBwB0zLDrMeta?.name ?? "home",
    path: homeejBwB0zLDrMeta?.path ?? "/home",
    meta: homeejBwB0zLDrMeta || {},
    alias: homeejBwB0zLDrMeta?.alias || [],
    redirect: homeejBwB0zLDrMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/home.vue").then(m => m.default || m)
  },
  {
    name: indexeq1fXCRHLdMeta?.name ?? "index",
    path: indexeq1fXCRHLdMeta?.path ?? "/",
    meta: indexeq1fXCRHLdMeta || {},
    alias: indexeq1fXCRHLdMeta?.alias || [],
    redirect: indexeq1fXCRHLdMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: integrationsH6xw4Lk9OOMeta?.name ?? "integrations",
    path: integrationsH6xw4Lk9OOMeta?.path ?? "/integrations",
    meta: integrationsH6xw4Lk9OOMeta || {},
    alias: integrationsH6xw4Lk9OOMeta?.alias || [],
    redirect: integrationsH6xw4Lk9OOMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/integrations.vue").then(m => m.default || m)
  },
  {
    name: loginQ4iGsW0SLdMeta?.name ?? "login",
    path: loginQ4iGsW0SLdMeta?.path ?? "/login",
    meta: loginQ4iGsW0SLdMeta || {},
    alias: loginQ4iGsW0SLdMeta?.alias || [],
    redirect: loginQ4iGsW0SLdMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/login.vue").then(m => m.default || m)
  },
  {
    name: my_45templatesNIyRdBnOFvMeta?.name ?? "my-templates",
    path: my_45templatesNIyRdBnOFvMeta?.path ?? "/my-templates",
    meta: my_45templatesNIyRdBnOFvMeta || {},
    alias: my_45templatesNIyRdBnOFvMeta?.alias || [],
    redirect: my_45templatesNIyRdBnOFvMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/my-templates.vue").then(m => m.default || m)
  },
  {
    name: notion_45ambassadors_45influencers3wOdOMGuh3Meta?.name ?? "notion-ambassadors-influencers",
    path: notion_45ambassadors_45influencers3wOdOMGuh3Meta?.path ?? "/notion-ambassadors-influencers",
    meta: notion_45ambassadors_45influencers3wOdOMGuh3Meta || {},
    alias: notion_45ambassadors_45influencers3wOdOMGuh3Meta?.alias || [],
    redirect: notion_45ambassadors_45influencers3wOdOMGuh3Meta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/notion-ambassadors-influencers.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93t9L0GF8bGcMeta?.name ?? "notion-form-slug",
    path: _91slug_93t9L0GF8bGcMeta?.path ?? "/notion-form/:slug()",
    meta: _91slug_93t9L0GF8bGcMeta || {},
    alias: _91slug_93t9L0GF8bGcMeta?.alias || [],
    redirect: _91slug_93t9L0GF8bGcMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/notion-form/[slug].vue").then(m => m.default || m)
  },
  {
    name: callbackQZ8R9sJbxtMeta?.name ?? "notion-callback",
    path: callbackQZ8R9sJbxtMeta?.path ?? "/notion/callback",
    meta: callbackQZ8R9sJbxtMeta || {},
    alias: callbackQZ8R9sJbxtMeta?.alias || [],
    redirect: callbackQZ8R9sJbxtMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/notion/callback.vue").then(m => m.default || m)
  },
  {
    name: guest_45callback3fsYYxck0mMeta?.name ?? "notion-guest-callback",
    path: guest_45callback3fsYYxck0mMeta?.path ?? "/notion/guest-callback",
    meta: guest_45callback3fsYYxck0mMeta || {},
    alias: guest_45callback3fsYYxck0mMeta?.alias || [],
    redirect: guest_45callback3fsYYxck0mMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/notion/guest-callback.vue").then(m => m.default || m)
  },
  {
    name: callbackZCrWFVkjEdMeta?.name ?? "oauth-callback",
    path: callbackZCrWFVkjEdMeta?.path ?? "/oauth/callback",
    meta: callbackZCrWFVkjEdMeta || {},
    alias: callbackZCrWFVkjEdMeta?.alias || [],
    redirect: callbackZCrWFVkjEdMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/oauth/callback.vue").then(m => m.default || m)
  },
  {
    name: partnersXeeGPGtXbmMeta?.name ?? "partners",
    path: partnersXeeGPGtXbmMeta?.path ?? "/partners",
    meta: partnersXeeGPGtXbmMeta || {},
    alias: partnersXeeGPGtXbmMeta?.alias || [],
    redirect: partnersXeeGPGtXbmMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/partners.vue").then(m => m.default || m)
  },
  {
    name: _91token_93Cs6mQg9XEzMeta?.name ?? "password-reset-token",
    path: _91token_93Cs6mQg9XEzMeta?.path ?? "/password/reset/:token()",
    meta: _91token_93Cs6mQg9XEzMeta || {},
    alias: _91token_93Cs6mQg9XEzMeta?.alias || [],
    redirect: _91token_93Cs6mQg9XEzMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/password/reset/[token].vue").then(m => m.default || m)
  },
  {
    name: indexoSYHoZqnvKMeta?.name ?? "password-reset",
    path: indexoSYHoZqnvKMeta?.path ?? "/password/reset",
    meta: indexoSYHoZqnvKMeta || {},
    alias: indexoSYHoZqnvKMeta?.alias || [],
    redirect: indexoSYHoZqnvKMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/password/reset/index.vue").then(m => m.default || m)
  },
  {
    name: pricingfZXvVYO8RGMeta?.name ?? "pricing",
    path: pricingfZXvVYO8RGMeta?.path ?? "/pricing",
    meta: pricingfZXvVYO8RGMeta || {},
    alias: pricingfZXvVYO8RGMeta?.alias || [],
    redirect: pricingfZXvVYO8RGMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/pricing.vue").then(m => m.default || m)
  },
  {
    name: privacy_45policykDsriv2LXLMeta?.name ?? "privacy-policy",
    path: privacy_45policykDsriv2LXLMeta?.path ?? "/privacy-policy",
    meta: privacy_45policykDsriv2LXLMeta || {},
    alias: privacy_45policykDsriv2LXLMeta?.alias || [],
    redirect: privacy_45policykDsriv2LXLMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/privacy-policy.vue").then(m => m.default || m)
  },
  {
    name: registerMzHW7Ea7d6Meta?.name ?? "register",
    path: registerMzHW7Ea7d6Meta?.path ?? "/register",
    meta: registerMzHW7Ea7d6Meta || {},
    alias: registerMzHW7Ea7d6Meta?.alias || [],
    redirect: registerMzHW7Ea7d6Meta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/register.vue").then(m => m.default || m)
  },
  {
    name: settingst6ZJUu99MhMeta?.name ?? undefined,
    path: settingst6ZJUu99MhMeta?.path ?? "/settings",
    meta: settingst6ZJUu99MhMeta || {},
    alias: settingst6ZJUu99MhMeta?.alias || [],
    redirect: settingst6ZJUu99MhMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: access_45tokensS8DOgTWbPWMeta?.name ?? "settings-access-tokens",
    path: access_45tokensS8DOgTWbPWMeta?.path ?? "access-tokens",
    meta: access_45tokensS8DOgTWbPWMeta || {},
    alias: access_45tokensS8DOgTWbPWMeta?.alias || [],
    redirect: access_45tokensS8DOgTWbPWMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/settings/access-tokens.vue").then(m => m.default || m)
  },
  {
    name: accountNH02FgtAVaMeta?.name ?? "settings-account",
    path: accountNH02FgtAVaMeta?.path ?? "account",
    meta: accountNH02FgtAVaMeta || {},
    alias: accountNH02FgtAVaMeta?.alias || [],
    redirect: accountNH02FgtAVaMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/settings/account.vue").then(m => m.default || m)
  },
  {
    name: adminDaey0iCB4fMeta?.name ?? "settings-admin",
    path: adminDaey0iCB4fMeta?.path ?? "admin",
    meta: adminDaey0iCB4fMeta || {},
    alias: adminDaey0iCB4fMeta?.alias || [],
    redirect: adminDaey0iCB4fMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/settings/admin.vue").then(m => m.default || m)
  },
  {
    name: billingkF5vtpXbwXMeta?.name ?? "settings-billing",
    path: billingkF5vtpXbwXMeta?.path ?? "billing",
    meta: billingkF5vtpXbwXMeta || {},
    alias: billingkF5vtpXbwXMeta?.alias || [],
    redirect: billingkF5vtpXbwXMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/settings/billing.vue").then(m => m.default || m)
  },
  {
    name: connectionsX0LkEngkfhMeta?.name ?? "settings-connections",
    path: connectionsX0LkEngkfhMeta?.path ?? "connections",
    meta: connectionsX0LkEngkfhMeta || {},
    alias: connectionsX0LkEngkfhMeta?.alias || [],
    redirect: connectionsX0LkEngkfhMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/settings/connections.vue").then(m => m.default || m)
  },
  {
    name: custom_45domainm6xz3YuNPiMeta?.name ?? "settings-custom-domain",
    path: custom_45domainm6xz3YuNPiMeta?.path ?? "custom-domain",
    meta: custom_45domainm6xz3YuNPiMeta || {},
    alias: custom_45domainm6xz3YuNPiMeta?.alias || [],
    redirect: custom_45domainm6xz3YuNPiMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/settings/custom-domain.vue").then(m => m.default || m)
  },
  {
    name: indexNTmrUSRWYOMeta?.name ?? "settings",
    path: indexNTmrUSRWYOMeta?.path ?? "",
    meta: indexNTmrUSRWYOMeta || {},
    alias: indexNTmrUSRWYOMeta?.alias || [],
    redirect: indexNTmrUSRWYOMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: passwordzZk9kZQRSdMeta?.name ?? "settings-password",
    path: passwordzZk9kZQRSdMeta?.path ?? "password",
    meta: passwordzZk9kZQRSdMeta || {},
    alias: passwordzZk9kZQRSdMeta?.alias || [],
    redirect: passwordzZk9kZQRSdMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/settings/password.vue").then(m => m.default || m)
  },
  {
    name: profileym752boy9PMeta?.name ?? "settings-profile",
    path: profileym752boy9PMeta?.path ?? "profile",
    meta: profileym752boy9PMeta || {},
    alias: profileym752boy9PMeta?.alias || [],
    redirect: profileym752boy9PMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/settings/profile.vue").then(m => m.default || m)
  },
  {
    name: workspacese22c0BwJ0CMeta?.name ?? "settings-workspaces",
    path: workspacese22c0BwJ0CMeta?.path ?? "workspaces",
    meta: workspacese22c0BwJ0CMeta || {},
    alias: workspacese22c0BwJ0CMeta?.alias || [],
    redirect: workspacese22c0BwJ0CMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/settings/workspaces.vue").then(m => m.default || m)
  }
]
  },
  {
    name: errorzbs0UebeVBMeta?.name ?? "subscriptions-error",
    path: errorzbs0UebeVBMeta?.path ?? "/subscriptions/error",
    meta: errorzbs0UebeVBMeta || {},
    alias: errorzbs0UebeVBMeta?.alias || [],
    redirect: errorzbs0UebeVBMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/subscriptions/error.vue").then(m => m.default || m)
  },
  {
    name: success6j15G8QwjtMeta?.name ?? "subscriptions-success",
    path: success6j15G8QwjtMeta?.path ?? "/subscriptions/success",
    meta: success6j15G8QwjtMeta || {},
    alias: success6j15G8QwjtMeta?.alias || [],
    redirect: success6j15G8QwjtMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/subscriptions/success.vue").then(m => m.default || m)
  },
  {
    name: terms_45conditionsOL1HeskQUyMeta?.name ?? "terms-conditions",
    path: terms_45conditionsOL1HeskQUyMeta?.path ?? "/terms-conditions",
    meta: terms_45conditionsOL1HeskQUyMeta || {},
    alias: terms_45conditionsOL1HeskQUyMeta?.alias || [],
    redirect: terms_45conditionsOL1HeskQUyMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/terms-conditions.vue").then(m => m.default || m)
  },
  {
    name: edityln2Y6YGecMeta?.name ?? "views-slug-edit",
    path: edityln2Y6YGecMeta?.path ?? "/views/:slug()/edit",
    meta: edityln2Y6YGecMeta || {},
    alias: edityln2Y6YGecMeta?.alias || [],
    redirect: edityln2Y6YGecMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/views/[slug]/edit.vue").then(m => m.default || m)
  },
  {
    name: index2Myx3WLy2NMeta?.name ?? "views-slug",
    path: index2Myx3WLy2NMeta?.path ?? "/views/:slug()",
    meta: index2Myx3WLy2NMeta || {},
    alias: index2Myx3WLy2NMeta?.alias || [],
    redirect: index2Myx3WLy2NMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/views/[slug]/index.vue").then(m => m.default || m)
  },
  {
    name: showYg4qbfivDOMeta?.name ?? undefined,
    path: showYg4qbfivDOMeta?.path ?? "/views/:slug()/show",
    meta: showYg4qbfivDOMeta || {},
    alias: showYg4qbfivDOMeta?.alias || [],
    redirect: showYg4qbfivDOMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/views/[slug]/show.vue").then(m => m.default || m),
    children: [
  {
    name: indexUxXc7eKnS2Meta?.name ?? "views-slug-show",
    path: indexUxXc7eKnS2Meta?.path ?? "",
    meta: indexUxXc7eKnS2Meta || {},
    alias: indexUxXc7eKnS2Meta?.alias || [],
    redirect: indexUxXc7eKnS2Meta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/views/[slug]/show/index.vue").then(m => m.default || m)
  },
  {
    name: sharefPThBvr6LIMeta?.name ?? "views-slug-show-share",
    path: sharefPThBvr6LIMeta?.path ?? "share",
    meta: sharefPThBvr6LIMeta || {},
    alias: sharefPThBvr6LIMeta?.alias || [],
    redirect: sharefPThBvr6LIMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/views/[slug]/show/share.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexfwC6rgHGkoMeta?.name ?? "views-create",
    path: indexfwC6rgHGkoMeta?.path ?? "/views/create",
    meta: indexfwC6rgHGkoMeta || {},
    alias: indexfwC6rgHGkoMeta?.alias || [],
    redirect: indexfwC6rgHGkoMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/views/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexb7hw1huBstMeta?.name ?? "views",
    path: indexb7hw1huBstMeta?.path ?? "/views",
    meta: indexb7hw1huBstMeta || {},
    alias: indexb7hw1huBstMeta?.alias || [],
    redirect: indexb7hw1huBstMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/views/index.vue").then(m => m.default || m)
  },
  {
    name: uploadsEkxyEqT69XMeta?.name ?? "views-uploads",
    path: uploadsEkxyEqT69XMeta?.path ?? "/views/uploads",
    meta: uploadsEkxyEqT69XMeta || {},
    alias: uploadsEkxyEqT69XMeta?.alias || [],
    redirect: uploadsEkxyEqT69XMeta?.redirect,
    component: () => import("/codebuild/output/src2320907562/src/notionforms/client/pages/views/uploads.vue").then(m => m.default || m)
  }
]